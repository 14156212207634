import { getPrismicCollectionDetails } from "./prismic";
import { Storage, StorageKey } from "./storage";

// NOTE: collect collection details
export async function getCollectionDetails(): Promise<{
  collection_details?: Array<string>;
  exif?: object;
} | void> {
  const result: {
    collection_details?: Array<string>;
    exif?: object;
  } = {};

  try {
    const collectionDetailsStr = Storage.get(StorageKey.COLLECTION_DETAILS);

    if (collectionDetailsStr?.length) {
      const stored = JSON.parse(collectionDetailsStr);

      // NOTE: clear collection_details if prismic data is differ than local
      const prismic = await getPrismicCollectionDetails();

      if (!prismic?.show_collection_details) {
        return;
      }

      result.collection_details = [];

      if (prismic.devices.length > 0) {
        result.collection_details.push(stored.device);

        if ("camera_not_flipped" in stored) {
          result.exif = {
            camera_not_flipped: stored.camera_not_flipped,
          };
        }
      }

      prismic.simple_labels.forEach((label) => {
        result.collection_details?.push(stored.labels[label.id!]);
      });

      result.collection_details = [
        ...result.collection_details,
        ...stored.data,
      ];

      // NOTE: unwrap arrays
      result.collection_details = result.collection_details.flat();

      // NOTE: extra clear of empty values
      result.collection_details = result.collection_details.filter(
        (i) => typeof i === "string"
      );

      return result;
    }
  } catch {}
}
