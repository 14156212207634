import { VideoMetadata } from "./api";

// NOTE: exif-like video dimensions
export async function getVideoMetadata(file: File): Promise<VideoMetadata> {
  const res = await new Promise<[number, number]>((resolve, reject) => {
    const el = document.createElement("video");
    el.style.display = "none";
    el.src = URL.createObjectURL(file);
    const handleLoad = () => {
      const resolution: [number, number] = [el.videoWidth, el.videoHeight];
      el.removeEventListener("loadedmetadata", handleLoad);
      el.removeEventListener("error", reject);
      el.remove();
      resolve(resolution);
    };
    el.addEventListener("loadedmetadata", handleLoad);
    el.addEventListener("error", reject);
  });

  return {
    PixelXDimension: {
      value: res[0],
      description: res[0].toString(),
    },
    PixelYDimension: {
      value: res[1],
      description: res[1].toString(),
    },
    "Image Width": {
      value: res[0],
      description: `${res[0]}px`,
    },
    "Image Height": {
      value: res[1],
      description: `${res[1]}px`,
    },
  };
}
