import { PSClickWrap, PSClickWrapProps } from "@pactsafe/pactsafe-react-sdk";
import { decodeJwt } from "jose";
import { StorageKey, Storage } from "lib/storage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const pcsAccessId = process.env.REACT_APP_PCS_ACCESS_ID;
const pcsGroupKey = process.env.REACT_APP_PCS_GROUP_KEY;

if (!pcsAccessId || !pcsGroupKey) {
  throw new Error(
    "PactSafe env vars are missing, (set REACT_APP_PCS_ACCESS_ID and REACT_APP_PCS_GROUP_KEY)"
  );
}

export function PSC(
  props: Omit<
    PSClickWrapProps,
    "signerID" | "accessId" | "groupKey" | "clickWrapStyle"
  > &
    Partial<Pick<PSClickWrapProps, "clickWrapStyle">>
) {
  const navigate = useNavigate();
  const [pcsOptions, setPcsOptions] = useState<PSClickWrapProps | null>();

  useEffect(() => {
    try {
      const token = Storage.get(StorageKey.TOKEN) as string;
      const payload = decodeJwt(token);
      return setPcsOptions({
        signerId: payload.sub,
        accessId: pcsAccessId!,
        groupKey: pcsGroupKey,
        clickWrapStyle: "scroll",
      });
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
      console.error("Cannot decode token", error);
    }
  }, [navigate]);

  if (!pcsOptions) {
    return null;
  }

  return <PSClickWrap {...pcsOptions} {...props} />;
}
