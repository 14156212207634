import { Link } from "components/Link";
import { ReactNode } from "react";

export function Agreement(props: {
  onClickLink: () => void;
  buttons: ReactNode;
}) {
  return (
    <div className="grid gap-6">
      <div className="grid gap-2 text-center">
        <h3 className="font-medium">Privacy Agreement</h3>
        <div>
          I hereby consent to the collection and processing of photos of my face
          and eyes by Tools For Humanity Corp. (TFH) for the purposes of product
          research and development. I understand I have the right to revoke my
          consent and I acknowledge TFH’s&nbsp;
          <Link to="https://worldcoin.pactsafe.io/rkuawsvk5.html#contract-rjmifoykjl">
            Privacy Notice
          </Link>
          .
        </div>
      </div>
      <div className="flex gap-2 [&>button]:w-full [&>button]:text-14 [&>button]:py-3">
        {props.buttons}
      </div>
    </div>
  );
}
