import { ReactComponent as Copy } from "assets/copy.svg";
import { Button, ButtonVariant } from "components/Form/Button";
import { Input } from "components/Form/Input";
import { Link } from "components/Link";
import { apiDeletePii } from "lib/api";
import { Storage, StorageKey } from "lib/storage";
import { useState } from "react";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { AppRoutes } from "types";

enum Screen {
  INIT = "init",
  SUBMITTED = "submitted",
}

export function DeleteAll(props: { onClose: () => void }) {
  const [screen, setScreen] = useState<Screen>(Screen.INIT);
  const [deletionId, setDeletionId] = useState<string>("");

  const handleClickDelete = useCallback(async () => {
    try {
      const deletionId = await apiDeletePii();
      setScreen(Screen.SUBMITTED);
      setDeletionId(deletionId);
      Storage.set(StorageKey.DELETION_ID, deletionId);
    } catch (err) {
      console.error(err);
      toast.error("Failed to request data deletion.");
    }
  }, []);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(deletionId);
    toast.success("Deletion ID copied to clipboard.", { autoClose: 2500 });
  }, [deletionId]);

  if (screen === Screen.SUBMITTED) {
    return (
      <div className="grid gap-6">
        <div className="grid grid-flow-row gap-3 text-center [&_p]:text-858494">
          <h3 className="font-medium">
            Your deletion request has been successfully submitted
          </h3>
          <p>
            Your data will be permanently deleted within the next 24 hours. Once
            the process is complete, you will no longer have access to your
            profile.
          </p>
          <p>
            You can track the status of your deletion request using your unique
            deletion ID at{" "}
            <Link to={AppRoutes.deletionRequest}>
              contributor-id.app/deletion-request
            </Link>
            .
          </p>
          <p>
            Be sure to copy and save your deletion ID somewhere safe. Do not
            share it with anyone.
          </p>
        </div>
        <div className="grid grid-flow-row gap-3">
          <Input
            defaultValue={deletionId}
            className="w-full"
            icon={
              <Copy
                className="text-858494 cursor-pointer"
                onClick={handleCopy}
              />
            }
            readOnly
          />
          <div className="grid grid-flow-col gap-2">
            <Button variant={ButtonVariant.FLAT_BORDER} onClick={props.onClose}>
              Done
            </Button>
            <Button to={AppRoutes.deletionRequest}>Track Status</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-6">
      <div className="grid gap-2 text-center">
        <h3 className="font-medium">Request a full data deletion</h3>
        <div>
          Are you sure you want to delete your data? This action cannot be
          undone.
        </div>
      </div>
      <div className="grid gap-2 grid-cols-2">
        <Button
          variant={ButtonVariant.FLAT_BORDER_DANGER}
          onClick={handleClickDelete}
        >
          Delete
        </Button>
        <Button onClick={props.onClose}>Cancel</Button>
      </div>
    </div>
  );
}
