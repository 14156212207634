import axiosLib, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import ExifReader from "exifreader";
import bcrypt from "bcryptjs";
import { cleanupExif } from "./cleanup-exif";
import { UploadType } from "contexts/UploadContext";
import { getCollectionDetails } from "./get-collection-details";
import { getExtensionFromMime } from "./mime-to-ext";
import { getVideoMetadata } from "./get-video-metadata";
import { Storage, StorageKey } from "./storage";

const axios = axiosLib.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

// NOTE: add token to request headers
axios.interceptors.request.use((config) => {
  const token = Storage.get(StorageKey.TOKEN);
  if (window && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// NOTE: api error interceptor
axios.interceptors.response.use(
  (r) => r,
  (e) => {
    if (e instanceof AxiosError && e.response) {
      throw new ApiError(e.response);
    }

    throw e;
  }
);

export class ApiError extends Error {
  status: number;
  code: string;
  detail: string;
  attr: string;

  constructor(response: AxiosResponse) {
    super(response.data.detail);
    this.status = response.status;
    this.code = response.data.code;
    this.detail = response.data.detail;
    this.attr = response.data.attr;
  }
}

export interface PiiDeletionMetadata {
  deletion_id: string;
  opt_out: ApiCheckPiiStatus;
  opt_out_timestamp: string;
  deleted_data_counts: Record<string, number>;
}

export enum ApiCheckPiiStatus {
  REQUESTED = "Requested",
  COMPLETED = "Completed",
}

export enum ApiCheckPiiStatusCode {
  NOT_FOUND = "not_found",
  INVALID = "invalid",
}

export interface VideoMetadata {
  PixelXDimension: {
    value: number;
    description: string;
  };
  PixelYDimension: {
    value: number;
    description: string;
  };
  "Image Width": {
    value: number;
    description: string;
  };
  "Image Height": {
    value: number;
    description: string;
  };
}

function getApiUploadUrl(uploadType = UploadType.REGULAR, isImage: boolean) {
  if (uploadType === UploadType.REGULAR) {
    return isImage ? "/face_capture" : "/video_capture";
  }

  return "/iris_capture";
}

export const apiUploadFile = async (
  file: File,
  capture_id: string,
  uploadType = UploadType.REGULAR
) => {
  const ext = getExtensionFromMime(file.type);
  const isImage = file.type.startsWith("image/");

  let exif: ExifReader.Tags | VideoMetadata | undefined = undefined;

  if (isImage) {
    try {
      exif = await ExifReader.load(file);
    } catch (err) {
      console.log("Cannot read exif data", err);
    }
  } else {
    exif = await getVideoMetadata(file);
    if (exif.PixelXDimension.value > exif.PixelYDimension.value) {
      const message =
        "Only portrait videos are accepted. Please select a different file.";
      console.log(message);
      toast.error(message);
      return;
    }
  }

  const collectionDetails = await getCollectionDetails();

  const now = new Date();

  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const year = String(now.getFullYear());
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  const timestamp = `${day}${month}${year}_${hours}${minutes}`;

  console.log("Generated timestamp:", timestamp);

  const params: {
    file_extension: string;
    exif?: object;
    capture_id: string;
    collection_details?: Array<string>;
    timestamp: string;
  } = {
    file_extension: ext,
    collection_details: collectionDetails?.collection_details,
    exif: cleanupExif({
      ...exif,
      ...collectionDetails?.exif,
    }),
    capture_id,
    timestamp,
  };

  const apiUrl = getApiUploadUrl(uploadType, isImage);

  const response = await axios.post(apiUrl, params);

  const { url, fields } = response.data;

  await axiosLib.postForm(url, { ...fields, file });
};

export const apiLogin = async (
  email: string,
  password: string
): Promise<string | never> => {
  return (
    await axios.post("/login", {
      email,
      password: bcrypt.hashSync(password, 10),
    })
  ).data.token;
};

export const apiSignup = async (
  bio_data: Record<string, string | boolean>
): Promise<string | never> => {
  return (await axios.post("/signup", { bio_data })).data.token;
};

export const apiDeletePii = async () => {
  return (await axios.delete("/pii")).data.deletion_id;
};

export const apiCheckPii = async (
  deletionId: string
): Promise<PiiDeletionMetadata> => {
  return (await axios.get(`/pii?deletion_id=${deletionId}`)).data
    .deletion_metadata;
};
