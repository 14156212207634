import clsx from "clsx";
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
  memo,
} from "react";

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  icon?: React.ReactNode;
}

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
    return (
      <div className="relative bg-f4f7ff rounded-xl overflow-clip">
        <input
          {...props}
          ref={ref}
          className={clsx(
            "w-full bg-transparent rounded-xl h-12 px-4.5 py-3 placeholder:text-d1d3d4 outline-transparent focus:outline-3461fd caret-3461fd",
            props.className
          )}
        />
        {props.icon && (
          <div className="absolute right-0 inset-y-0 bg-gradient-to-l from-f4f7ff via-f4f7ff to-transparent pl-8 pr-2.5 grid items-center">
            {props.icon}
          </div>
        )}
      </div>
    );
  })
);
