import { FC, ReactNode, SVGProps } from "react";
import { ReactComponent as Selfie } from "assets/selfie.svg";
import { ReactComponent as Bulb } from "assets/bulb.svg";
import { ReactComponent as Note } from "assets/note.svg";
import { ReactComponent as Iris } from "assets/iris.svg";
type ExplanationItem = {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: ReactNode;
  description: JSX.Element;
};

export const ExplanationIcons = {
  note: Note,
  bulb: Bulb,
  selfie: Selfie,
  iris: Iris,
};

export function Explanation(props: { items: Array<ExplanationItem> }) {
  return (
    <ul className="space-y-8">
      {props.items.map((item, index) => (
        <li key={index} className="flex gap-5">
          <item.icon className="flex-none w-8 h-8 text-3461fd" />
          <div className="space-y-2">
            <h2 className="font-medium leading-1.2">{item.title}</h2>
            <div className="text-858494 text-14">{item.description}</div>
          </div>
        </li>
      ))}
    </ul>
  );
}
