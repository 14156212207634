export enum StorageKey {
  TOKEN = "token",
  COLLECTION_DETAILS = "collection_details",
  DELETION_ID = "deletion_id",
}

const PRESERVE_KEYS: Array<StorageKey> = [StorageKey.DELETION_ID];

export class Storage {
  static get(key: StorageKey) {
    return window.localStorage.getItem(key);
  }

  static set(key: StorageKey, value: string) {
    window.localStorage.setItem(key, value);
  }

  static remove(key: StorageKey) {
    window.localStorage.removeItem(key);
  }

  static clear() {
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key && !PRESERVE_KEYS.includes(key as StorageKey)) {
        window.localStorage.removeItem(key);
      }
    }
  }
}
