import { ReactComponent as TrashBin } from "assets/trash-bin.svg";
import { ReactComponent as Clock } from "assets/clock.svg";
import { ReactComponent as Folder } from "assets/folder.svg";
import { Layout } from "components/Layout";
import { Link } from "components/Link";
import { Button } from "components/Form/Button";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Input } from "components/Form/Input";
import {
  apiCheckPii,
  ApiCheckPiiStatus,
  ApiCheckPiiStatusCode,
  ApiError,
  PiiDeletionMetadata,
} from "lib/api";
import { Storage, StorageKey } from "lib/storage";
import { toast } from "react-toastify";
import { appLogger } from "lib/datadog";

enum Screen {
  LOADING = "loading",
  INIT = "init",
  PROCESSING = "processing",
  COMPLETED = "completed",
  NOT_FOUND = "not-found",
}

export function DeletionRequest() {
  const [deletionId, setDeletionId] = useState("");
  const [screen, setScreen] = useState<Screen>(Screen.LOADING);
  const [deletionMetadata, setDeletionMetadata] =
    useState<PiiDeletionMetadata | null>(null);

  const deletedFileCount = useMemo(() => {
    if (!deletionMetadata) {
      return 0;
    }

    return Object.values(deletionMetadata.deleted_data_counts).reduce(
      (acc, count) => acc + count,
      0
    );
  }, [deletionMetadata]);

  useEffect(() => {
    const deletionId = Storage.get(StorageKey.DELETION_ID);

    if (deletionId) {
      setDeletionId(deletionId);
    }

    setScreen(Screen.INIT);
  }, []);

  const handleContinue = useCallback(async () => {
    try {
      const response = await apiCheckPii(deletionId);

      switch (response.opt_out.toLowerCase()) {
        case ApiCheckPiiStatus.REQUESTED.toLowerCase():
          Storage.set(StorageKey.DELETION_ID, deletionId);
          setScreen(Screen.PROCESSING);
          break;
        case ApiCheckPiiStatus.COMPLETED.toLowerCase():
          Storage.set(StorageKey.DELETION_ID, deletionId);
          setDeletionMetadata(response);
          setScreen(Screen.COMPLETED);
          break;
        default:
          appLogger.error("Unable to parse deletion status", { response });
          toast.error("Something went wrong. Please try again.");
          break;
      }
    } catch (error) {
      if (error instanceof ApiError) {
        switch (error.code.toLowerCase()) {
          case ApiCheckPiiStatusCode.NOT_FOUND.toLowerCase():
          case ApiCheckPiiStatusCode.INVALID.toLowerCase():
            return setScreen(Screen.NOT_FOUND);
        }
      }

      appLogger.error("Unhandled parsing deletion request", { error });
      toast.error("Something went wrong. Please try again.");
    }
  }, [deletionId]);

  if (screen === Screen.LOADING) {
    return null;
  }

  return (
    <Layout
      showTitle
      className="grid grid-rows-[1fr_auto] content-stretch items-center"
    >
      <div className="grid grid-flow-row gap-10">
        <div className="grid grid-flow-row gap-4 justify-items-center text-center font-medium text-858494">
          <TrashBin
            className={clsx({
              "text-3461fd": screen !== Screen.COMPLETED,
              "text-39b54a": screen === Screen.COMPLETED,
            })}
          />
          <p>
            {[Screen.INIT, Screen.NOT_FOUND].includes(screen) && (
              <>
                Please provide a unique deletion id, to check the status of your
                deletion request.
              </>
            )}
            {screen === Screen.PROCESSING && (
              <>
                Your deletion request is in progress. It may take up to 24 hours
                for your data to be fully deleted. Please check back later.
              </>
            )}
            {screen === Screen.COMPLETED && (
              <>
                Deletion request for
                <br />
                <span>{deletionId}</span>
                <br />
                has been successfully executed.
              </>
            )}
          </p>
        </div>
        {screen !== Screen.COMPLETED && (
          <div className="grid gap-4">
            <Input
              className="w-full"
              placeholder="Deletion ID"
              defaultValue={deletionId}
              onChange={(e) => setDeletionId(e.target.value)}
              data-1p-ignore
            />
            {screen === Screen.NOT_FOUND && (
              <p className="text-center text-fd380f">
                ID is Invalid. Please check your input and try again.
              </p>
            )}
            <Button
              className="w-full"
              disabled={deletionId.length <= 0}
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        )}
        {screen === Screen.COMPLETED && deletionMetadata && (
          <div className="grid grid-flow-row gap-6 px-7 py-5 rounded-md border border-f4f7ff font-medium">
            <div className="grid grid-flow-col justify-start gap-4">
              <Clock className="w-fit text-3461fd" />
              <div className="grid gap-2">
                <p>Execution date:</p>
                <p className="text-858494">
                  {new Date(
                    Number(deletionMetadata.opt_out_timestamp) * 1000
                  ).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="grid grid-flow-col justify-start gap-4">
              <Folder className=" text-3461fd" />
              <div className="grid gap-2">
                <p>Deleted:</p>
                <p className="text-858494">
                  {deletedFileCount} files and all associated user intformation.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="self-end text-center text-858494">
        For support, contact{" "}
        <Link
          to="mailto:contribute@toolsforhumanity.com"
          target="_blank"
          rel="noreferrer"
        >
          contribute@toolsforhumanity.com
        </Link>
      </div>
    </Layout>
  );
}
