import { forwardRef } from "react";
import { Input, InputProps } from "./Input";
import { ReactComponent as Eye } from "assets/eye.svg";
import clsx from "clsx";

export const InputPassword = forwardRef<HTMLInputElement, InputProps>(
  function InputPassword(props, ref) {
    return (
      <Input
        type="password"
        {...props}
        className={clsx("w-full", props.className)}
        ref={ref}
        icon={
          <Eye
            className="absolute top-1/2 right-3 -translate-y-1/2"
            onClick={(e) => {
              e.preventDefault();
              const input =
                e.currentTarget.parentElement?.querySelector("input");
              if (input) {
                input.type = input.type === "password" ? "text" : "password";
              }
            }}
          />
        }
      />
    );
  }
);
