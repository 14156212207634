import clsx from "clsx";
import { Button, ButtonVariant, getButtonStyle } from "components/Form/Button";
import { Layout } from "components/Layout";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppRoutes } from "types";
import { Explanation, ExplanationIcons } from "components/Explanation";
import { GalleryPreview } from "components/GalleryPreview";
import { UploadContext, UploadType } from "contexts/UploadContext";
import { usePrivacyModal } from "hooks/usePrivacyModal";
import { CollectionDetailsContext } from "contexts/CollectionDetails";
import { useNavigate } from "react-router-dom";

export function IrisUpload() {
  const [init, setInit] = useState<boolean>(false);

  const {
    files: images,
    setFiles: setImages,
    resetFiles: resetImages,
    upload,
  } = useContext(UploadContext);
  const { config } = useContext(CollectionDetailsContext);
  const privacyModal = usePrivacyModal();
  const navigate = useNavigate();

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      if (event.target.files) {
        setImages(Array.from(event.target.files));
      }
    },
    [setImages]
  );

  useEffect(() => {
    if (!config || privacyModal.accepted === null) {
      return;
    }

    if (
      privacyModal.accepted === false ||
      (config?.enable_iris_uploads || false) === false
    ) {
      navigate(AppRoutes.home);
      return;
    }

    setInit(true);
  }, [config, navigate, privacyModal.accepted]);

  if (!init) {
    return null;
  }

  return (
    <Layout
      className="space-y-8"
      toolbar={
        <>
          {images.length <= 0 && (
            <>
              <label className={clsx(getButtonStyle(), "cursor-pointer")}>
                <input
                  type="file"
                  accept="image/*, .dng"
                  multiple
                  onChange={handleChangeInput}
                  hidden
                />
                <span>Select iris images</span>
              </label>
              <Button to={AppRoutes.home} variant={ButtonVariant.FLAT}>
                Cancel
              </Button>
            </>
          )}

          {images.length > 0 && (
            <>
              <Button onClick={() => upload(UploadType.IRIS)}>Upload</Button>
              <Button onClick={resetImages} variant={ButtonVariant.FLAT}>
                Choose another
              </Button>
            </>
          )}
        </>
      }
    >
      <h1 className="text-24 font-semibold text-center">
        {images.length <= 0 && "Choose images"}
        {images.length > 0 && "Preview images"}
      </h1>

      {images.length > 0 && (
        <div className="text-858494 leading-5 text-center">
          Review iris images, and labels carefully. <br />
          Correct any mismatches before uploading.
        </div>
      )}

      {images.length <= 0 && (
        <Explanation
          items={[
            {
              icon: ExplanationIcons.note,
              title: "Input collection details",
              description: (
                <p>
                  Add any relevant information you want to upload along with the
                  files. Ensure all details entered apply consistently across
                  all files uploaded in the session.
                </p>
              ),
            },
            {
              icon: ExplanationIcons.iris,
              title: "Select iris images to upload",
              description: (
                <p>
                  Tap on the button to select iris images from your gallery you
                  intend to upload. Preview them and confirm the upload.
                </p>
              ),
            },
          ]}
        />
      )}

      {images.length > 0 && <GalleryPreview isRemovable showLabels />}
    </Layout>
  );
}
