import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { appLogger } from "lib/datadog";
import { apiUploadFile } from "lib/api";
import { Uploading } from "./components/Uploading";
import { Error as ErrorComponent } from "./components/Error";
import { Success } from "./components/Success";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { MIN_FILE_SIZE, MAX_IMAGE_SIZE, MAX_VIDEO_SIZE } from "types";

enum Screens {
  App,
  Upload,
  Success,
  Error,
}

enum Errors {
  DUPLICATION = "duplication",
  SMALL_SIZE = "small size",
  LARGE_SIZE = "large size",
}

export enum UploadType {
  REGULAR = "regular",
  IRIS = "iris",
}

export type UploadContextImages = Array<File>;

export interface UploadContextInterface {
  files: UploadContextImages;
  addFile: (file: File) => void;
  setFiles: (files: Array<File>) => void;
  resetFiles: () => void;
  removeFile: (idx: number) => void;
  captureId?: string;
  upload: (type?: UploadType) => Promise<void>;
}

export const UploadContext = createContext({} as UploadContextInterface);

function isSameFile(a: File, b: File): boolean {
  return a.name === b.name && a.size === b.size && a.type === b.type;
}

export const UploadContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [screen, setScreen] = useState(Screens.App);
  const [files, setFiles] = useState<UploadContextImages>([]);
  const [captureId, setCaptureId] = useState<string>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);

  const loc = useLocation();

  const addFile = useCallback(
    async (file: File | unknown) =>
      setFiles((prev) => {
        if (!(file instanceof File)) {
          return prev;
        }

        if (prev.some((_file) => isSameFile(file, _file))) {
          toast.warn("File already added.");
          return prev;
        }

        if (file.size < MIN_FILE_SIZE) {
          toast.warn("File is too small, choose another one");
          return prev;
        }

        if (file.type.startsWith("image") && file.size > MAX_IMAGE_SIZE) {
          toast.warn("Image is too large, choose another one");
          return prev;
        }

        if (file.type.startsWith("video") && file.size > MAX_VIDEO_SIZE) {
          toast.warn("Video is too large, choose another one");
          return prev;
        }

        return [...prev, file];
      }),
    []
  );

  const _setFiles = useCallback((files: Array<File>) => {
    const _files: Array<File> = [];

    const errors: Array<Errors> = [];

    for (const file of files) {
      if (_files.some((_file) => isSameFile(file, _file))) {
        if (!errors.includes(Errors.DUPLICATION)) {
          errors.push(Errors.DUPLICATION);
        }
        continue;
      }

      if (file.size < MIN_FILE_SIZE) {
        if (!errors.includes(Errors.SMALL_SIZE)) {
          errors.push(Errors.SMALL_SIZE);
        }
        continue;
      }

      if (
        (file.type.startsWith("image") && file.size > MAX_IMAGE_SIZE) ||
        (file.type.startsWith("video") && file.size > MAX_VIDEO_SIZE)
      ) {
        if (!errors.includes(Errors.LARGE_SIZE)) {
          errors.push(Errors.LARGE_SIZE);
        }
        continue;
      }

      _files.push(file);
    }

    if (errors.length > 0) {
      toast.warn(
        `Some files were not added due to errors: ${errors.join(", ")}.`
      );
    }

    setFiles(_files);
  }, []);

  const resetFiles = useCallback(() => setFiles([]), []);

  const removeFile = useCallback((idx: number) => {
    setFiles((prev) => prev.filter((_, _idx) => _idx !== idx));
  }, []);

  useEffect(() => {
    setScreen(Screens.App);
  }, [loc.pathname]);

  const upload = useCallback(
    async (type = UploadType.REGULAR) => {
      try {
        // NOTE: IOS don't allow use crypto for non localhost/https websites, so this is a workaround. (only for development)
        let captureId = "00000000-0000-0000-0000-000000000000";
        if (typeof window?.crypto?.randomUUID === "function") {
          captureId = window.crypto.randomUUID();
        }
        setCaptureId(captureId);

        setUploadProgress(0);
        setScreen(Screens.Upload);

        appLogger.log("Start image submission.");

        const errors: Array<unknown> = [];

        await Promise.all(
          files.map(async (file) => {
            let attempt = 0;

            while (attempt < 3) {
              try {
                await apiUploadFile(file, captureId, type);
                setUploadProgress((p) => p + 1);
                break;
              } catch (error) {
                if (attempt === 2) {
                  errors.push(error);
                }
                attempt++;
              }
            }
          })
        );

        if (errors.length > 0) {
          appLogger.error("Failed to upload image.", {
            images: files.length,
            errors,
          });

          if (errors.length === files.length) {
            throw new Error("Failed to upload image.");
          }

          toast.error("Failed to upload some images.");
        } else {
          appLogger.log("Successful image submission.");
        }

        setScreen(Screens.Success);
      } catch (err) {
        console.error(err);
        setScreen(Screens.Error);
        appLogger.error("Error submitting image.", err as object);
        setErrorMessage("Something went wrong, please try again.");
      } finally {
        setFiles([]);
      }
    },
    [files]
  );

  return (
    <UploadContext.Provider
      value={{
        files,
        addFile,
        setFiles: _setFiles,
        resetFiles,
        removeFile,
        upload,
        captureId,
      }}
    >
      {screen === Screens.App && children}
      {screen === Screens.Upload && (
        <Uploading files={files} progress={uploadProgress} />
      )}
      {screen === Screens.Success && <Success />}
      {screen === Screens.Error && <ErrorComponent message={errorMessage} />}
    </UploadContext.Provider>
  );
};
