import { memo, useEffect, useState } from "react";
import { Email } from "./Email";
import { Information } from "./Information";
import * as jose from "jose";
import { AppRoutes, SignupScreens } from "types";
import { useNavigate } from "react-router-dom";
import { Storage, StorageKey } from "lib/storage";

export const SignUp = memo(function SignUp() {
  const navigate = useNavigate();
  const [step, setStep] = useState<SignupScreens>(SignupScreens.Email);

  useEffect(() => {
    try {
      const token = Storage.get(StorageKey.TOKEN);
      if (token) {
        if (jose.decodeJwt(token).registered) {
          navigate(AppRoutes.home, { replace: true });
        } else {
          setStep(SignupScreens.Information);
        }
      }
    } catch {
      Storage.remove(StorageKey.TOKEN);
      setStep(SignupScreens.Email);
    }
  }, [navigate]);

  switch (step) {
    case SignupScreens.Email:
      return <Email setStep={setStep} />;
    case SignupScreens.Information:
      return <Information setStep={setStep} />;
    default:
      return null;
  }
});
