import { decodeJwt } from "jose";
import { Storage, StorageKey } from "./storage";

export const getUserEmail = () => {
  try {
    const payload = decodeJwt(Storage.get(StorageKey.TOKEN) || "");
    return (payload.email as string) ?? "Unknown user";
  } catch (err) {
    return "Unknown user";
  }
};
