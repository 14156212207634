import { Button, ButtonVariant } from "components/Form/Button";
import { Link } from "components/Link";

export function CopyImages(props: { onClose: () => void }) {
  return (
    <div className="grid gap-6">
      <div className="grid gap-2 text-center">
        <h3 className="font-medium">Request copy of image data</h3>
        <div>
          To request a copy of your image data, you'll be prompted to send an
          email to&nbsp;
          <Link to="mailto:contribute@toolsforhumanity.com">
            contribute@toolsforhumanity.com
          </Link>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-2">
        <Button
          variant={ButtonVariant.FLAT}
          href={[
            "mailto:contribute@toolsforhumanity.com",
            "?subject=Request copy of image data",
            "&body=I want to request copy of image data.",
          ].join("")}
        >
          Continue
        </Button>
        <Button onClick={props.onClose}>Done</Button>
      </div>
    </div>
  );
}
