import {
  BooleanField,
  GroupField,
  KeyTextField,
  NumberField,
  PrismicDocument,
} from "@prismicio/client/src/types/value/document";

export enum AppRoutes {
  home = "/",
  login = "/login",
  signup = "/signup",
  code = "/code",
  capture = "/capture",
  orb = "/orb",
  logout = "/logout",
  loginOrSignup = "/login-or-signup",
  collectionDetails = "/collection-details",
  batchUpload = "/batch-upload",
  irisUpload = "/iris-upload",
  deletionRequest = "/deletion-request",
}

export const ALL_ROUTES = Object.values(AppRoutes);

export const PUBLIC_ROUTES = [
  AppRoutes.login,
  AppRoutes.loginOrSignup,
  AppRoutes.logout,
  AppRoutes.signup,
  AppRoutes.deletionRequest,
];

export const PRIVATE_ROUTES = ALL_ROUTES.filter(
  (route) => !PUBLIC_ROUTES.includes(route)
);

export enum SignupScreens {
  Email,
  Information,
}

export const MIN_FILE_SIZE = 10 * 1024; // 10KB
export const MAX_IMAGE_SIZE = 25 * 1024 * 1024; // 25MB
export const MAX_VIDEO_SIZE = 250 * 1024 * 1024; // 250MB

export type Contributor = string;

export type PrismicCollectionDetails = PrismicDocument<{
  enable_capture: BooleanField;
  show_camera_not_flipped: BooleanField;
  enable_file_uploads: BooleanField;
  enable_iris_uploads: BooleanField;
  show_collection_details: BooleanField;
  capture_image_count: NumberField;
  devices: GroupField<{
    key: KeyTextField;
    label: KeyTextField;
    camera_not_flipped: BooleanField;
  }>;
  simple_labels: GroupField<{
    id: KeyTextField;
    title: KeyTextField;
    multiselect: BooleanField;
    required: BooleanField;
    labels: KeyTextField;
    with_search: BooleanField;
  }>;
}>;
