import { PrismicCollectionDetails } from "types";
import { prismicClient, prismicFilter } from "./prismic-client";
import { decodeJwt, JWTPayload } from "jose";
import { Storage, StorageKey } from "./storage";

export async function getPrismicCollectionDetails() {
  try {
    const token = Storage.get(StorageKey.TOKEN);

    if (!token) {
      throw new Error("token not found");
    }

    const jwt = decodeJwt(token) as JWTPayload & { contributor: string };
    const contributor = jwt.contributor;

    const page = await prismicClient.getByType<PrismicCollectionDetails>(
      "collection_details",
      {
        filters: [
          prismicFilter.at("document.tags", [
            contributor,
            ...(process.env.NODE_ENV === "development" ? ["dev"] : []),
          ]),
        ],
      }
    );

    return page.results[0].data;
  } catch (err) {
    console.log("Error fetching collection details from Prismic", err);
    return null;
  }
}
