import clsx from "clsx";
import { useMemo } from "react";
import { Link, LinkProps } from "react-router-dom";

export enum ButtonVariant {
  DEFAULT = "default",
  FLAT = "flat",
  LINK = "link",
  DANGER = "danger",
  FLAT_BORDER = "flat-border",
  FLAT_BORDER_DANGER = "flat-border-danger",
}

type Props = {
  variant?: ButtonVariant;
  unstyled?: boolean;
} & (
  | ({ to?: never; href?: never } & JSX.IntrinsicElements["button"])
  | ({ href?: never } & LinkProps)
  | ({ to?: never; href?: string } & JSX.IntrinsicElements["a"])
);

export function getButtonStyle(
  variant: Props["variant"] = ButtonVariant.DEFAULT
): string {
  return clsx(
    "py-4.5 px-9 rounded-9 leading-1.2 font-medium text-center transition-colors",
    "disabled:saturate-50 disabled:opacity-40 disabled:pointer-events-none",
    {
      "bg-3461fd text-white hover:bg-3461fd/80":
        variant === ButtonVariant.DEFAULT,
      "text-858494 hover:text-191c20": variant === ButtonVariant.FLAT,
      "text-ff6848 hover:bg-ff6848/5": variant === ButtonVariant.DANGER,
      "border border-f4f7ff hover:bg-f4f7ff":
        variant === ButtonVariant.FLAT_BORDER,
      "border border-f4f7ff hover:bg-fd380f hover:text-white":
        variant === ButtonVariant.FLAT_BORDER_DANGER,
    }
  );
}

export function Button(props: Props) {
  const {
    variant = ButtonVariant.DEFAULT,
    unstyled = false,
    ...restProps
  } = props;

  const Component = useMemo(() => {
    if (restProps.to) {
      return Link;
    } else if (restProps.href) {
      return "a";
    } else {
      return "button";
    }
  }, [restProps]);

  const className = useMemo(
    () => clsx(unstyled ? "" : getButtonStyle(variant), props.className),
    [props.className, unstyled, variant]
  );

  return (
    // @ts-ignore
    <Component {...restProps} className={className}>
      {props.children}
    </Component>
  );
}
