/**
 * Returns the extension (with leading dot) for a given MIME type.
 * @link https://github.com/jshttp/mime-db/blob/master/db.json
 * @link https://stackoverflow.com/a/47612661
 */
export function getExtensionFromMime(mime: string): string {
  return (
    "." +
    ({
      "image/jpeg": "jpg",
      "image/png": "png",
      "image/webp": "webp",
      "video/quicktime": "mov",
      "video/mp4": "mp4",
      "video/3gpp": "3gp",
      "image/heic": "heic",
      "image/heif": "heif",

      // raw formats
      "image/x-sony-arw": "arw",
      "image/x-canon-cr2": "cr2",
      "image/x-canon-crw": "crw",
      "image/x-kodak-dcr": "dcr",
      "image/x-adobe-dng": "dng",
      "image/x-epson-erf": "erf",
      "image/x-kodak-k25": "k25",
      "image/x-kodak-kdc": "kdc",
      "image/x-minolta-mrw": "mrw",
      "image/x-nikon-nef": "nef",
      "image/x-olympus-orf": "orf",
      "image/x-pentax-pef": "pef",
      "image/x-fuji-raf": "raf",
      "image/x-panasonic-raw": "raw",
      "image/x-sony-sr2": "sr2",
      "image/x-sony-srf": "srf",
      "image/x-sigma-x3f": "x3f",
    }[mime] || "jpg")
  );
}
